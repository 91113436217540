@use '../../../variables.scss' as *;

.tabs {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    margin: 0;
    margin-inline: auto;
    padding: 0;
    list-style-type: none;
}

.tab {
    width: 12px;
    height: 4px;
    border-radius: 5px;
    transition: width 0.3s;

    &--active {
        width: 24px;
    }

    &--DARK_BLUE {
        background-color: $background-color_tab-dark-blue;
    }

    &--active--DARK_BLUE {
        background-color: $background-color_tab-dark-blue-active;
    }

    &--LIGHT_BLUE {
        background-color: $background-color_tab-light-blue;
    }

    &--active--LIGHT_BLUE {
        background-color: $background-color_tab-light-blue-active;
    }

    &--GREY {
        background-color: $background-color_tab-grey;
    }

    &--active--GREY {
        background-color: $background-color_tab-grey-active;
    }
}

.tab button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    color: transparent;
    background-color: transparent;
    cursor: pointer;
}
