@use '../../../variables.scss' as *;

.modal-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 122px 65px;
    background-color: $background-color_modal-wrapper;
    z-index: $z-index_slider-modal;

    @include respond-to-desktop {
        padding: 90px;
    }

    @media (max-height: 500px) {
        padding: 15px;
    }
}

.modal {
    height: 100%;
    margin: 0 auto;
}

.modal-body {
    position: relative;
    height: calc(100% - 28px);
    margin: auto;
}

.image-wrapper {
    position: relative;
    height: 100%;
    margin: 0 auto;
}

.close-icon-wrapper {
    position: absolute;
    top: -12px;
    right: 0;
    transform: translateX(100%) translateY(-100%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 0;
    background-color: $background-color_modal-icon;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    & > svg {
        width: 12px;
    }

    @include respond-to-desktop {
        top: -8px;
        transform: translateX(200%) translateY(-100%);
    }

    @media (max-height: 500px) {
        top: 0;
        transform: translateX(200%) translateY(0);
    }
}

.modal-image-button {
    display: block;
    position: relative;
    height: 100%;
    aspect-ratio: 332 / 720;
    padding: 0;
    margin: 0 auto;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.modal-image-button img {
    display: block;
    height: 100%;
    width: auto;
}

.slider-control--left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-200%) translateY(-50%);
}

.slider-control--right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(200%) translateY(-50%);
}

.modal-footer {
    padding-top: 24px;
}

.basic-slider-control {
    @media (min-width: $breakpoint_slider-popup) {
        display: none;
    }
}

.fullwidth-slider-control {
    display: none;

    @media (min-width: $breakpoint_slider-popup) {
        display: flex;
    }
}
