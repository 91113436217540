@import '../../variables.scss';

.footer {
  // NOTE: Compensation to add color for upper section
  margin-top: -30px;
  padding: 56px 24px;
  padding-top: 86px;
  background-color: $color_footer-background;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  max-width: $max-width_text-block;
  margin: 0 auto;
}

.footer-navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-wrapper > * {
  max-width: 154px;
  height: auto;
}

.footer-nav {
  margin: 0 auto;
}

.nav-list {
  @include reset-list;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @include respond-to-desktop {
    flex-direction: row;
    justify-content: center;
    gap: 40px;
  }
}

.nav-item > a {
  position: relative;
  display: block;
  color: $color_footer-link;
  text-decoration: none;
  font-size: 20px;
  line-height: 20px;
}

.copyright-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  position: relative;
  padding-top: 25px;
  text-align: center;

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background-color: $color_decoration-line;
    content: '';

    @include respond-to-desktop {
      width: 89%;
    }
  }

  @include respond-to-desktop {
    flex-direction: row;
    gap: 16px;
  }
}

.copyright-text {
  text-align: center;
  color: $color_white;
  opacity: 50%;
  white-space: pre-line;

  @include respond-to-desktop {
    text-align: left;
  }
}
