@use '../../variables.scss' as *;

.slider {
    position: relative;
    margin-inline: auto;
}

.slider-track {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    transition: transform 250ms linear;
}

.slider-tabs {
    padding-top: 24px;

    @include respond-to-desktop {
        display: none;
    }
}

.visually-hidden {
    @include visually-hidden;
}

.testimonials-control {
    top: 0;
    transform: translateY(-150%);

    & > * {
        background-color: $background-color_testimonial-control;
    }
}

.hide-for-gallery {
    @media (min-width: $breakpoint_slider-popup) {
        display: none !important;
    }
}

.hide-for-cards {
    @media (min-width: $breakpoint_slider-testimonials) {
        display: none !important;
    }
}

.cancel-translate-gallery {
    @media (min-width: $breakpoint_slider-popup) {
        transform: translateX(0) !important;
    }
}

.cancel-translate-cards {
    @media (min-width: $breakpoint_slider-testimonials) {
        transform: translateX(0) !important;
    }
}
