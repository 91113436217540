@import '../../variables.scss';

.app-description {
  position: relative;
  min-height: 837px;
  padding-block: 88px;
  border-radius: $border-radius_section;
  background-image: url('../../assets/images/app-description/cities-background-mobile-2x.webp');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 2848px 838px;
  z-index: 4;

  @include respond-to-desktop {
    background-image: url('../../assets/images/app-description/cities-background-2x.webp');
  }

  @media (min-width: 2800px) {
    background-size: cover;
  }
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  max-width: 1280px;
  margin: 0 auto;
}

.app-description-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin: 0 auto;
  padding-inline: 24px;

  @include respond-to-desktop {
    padding-inline: 50px;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.icon-wrapper {
  margin: 0;
  width: 96px;
  margin: 0 auto;
}

.logo-wrapper {
  margin: 0;

  & > * {
    height: 76px;
  }
}

.app-links-list-wrapper {
  padding-inline: 20px;
}

.app-links-list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
}

.app-button-link {
  display: block;
  transition: opacity 300ms;
  height: 48px;

  &:hover {
    opacity: 0.75;
    transition: opacity 300ms;
  }

  & > svg {
    height: 48px;
    width: auto;
  }
}
