// SECTION: VARIABLES
$max-width_page: 1920px;
$max-width_text-block: 1118px;

$breakpoint_slider-popup: 1280px;
$breakpoint_slider-testimonials: 1587px;

$color_white: #ffffff;
$color_text: #373d4d;
$color_text_50_opacity: #373d4d80;
$color_title: #000000;
$color_contact-link: #0243ea;
$color_footer-link: #93b1ff;
$color_copyright-text: #ffffff80;
$color_footer-background: #1a191c;
$color_background-pattern: #ffeffd;
$color_decoration-line: #2d2a34;

$background-color_modal-wrapper: #0e0f0fe6;
$background-color_modal-icon: #00000099;

$background-color_tab-light-blue: #7974e066;
$background-color_tab-light-blue-active: #7974e0;

$background-color_tab-dark-blue: #373d4d66;
$background-color_tab-dark-blue-active: #373d4d;

$background-color_tab-grey: #ffffff66;
$background-color_tab-grey-active: #ffffff;

$background-color_testimonial-control: #ce85cb99;

$box-shadow_feature-card: 0px 24px 24px -8px rgba(233, 205, 235, 1);
$box-shadow_feature-card-image: 25px 25px 45px 12px rgba(56, 1, 1, 0.5);

$background-color_cookies-popup: #3a2b3a;
$background-color_cookies-button: #ff64e6;

$border-radius_features-card: 24px;
$border-radius_section: 0px 0px 32px 32px;

$z-index_cookies-popup: 10;
$z-index_slider-modal: 20;

// SECTION: MIXINS
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-button {
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

@mixin respond-to-desktop {
  @media (min-width: 960px) {
    @content;
  }
}
