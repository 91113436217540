@import '../../variables.scss';

.company-info {
  position: relative;
  padding: 48px 24px;
  border-radius: $border-radius_section;
  background-color: $color_white;
  z-index: 2;

  @include respond-to-desktop {
    padding-block: 80px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  margin: 0 auto;
  text-align: center;
  max-width: $max-width_text-block;
  margin: 0 auto;
}

.about-us,
.contact-us {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

.email {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  color: $color_contact-link;
  text-decoration-line: none;
}

.about-us-text {
  text-align: left;
}
