@import '../../variables.scss';

.header {
  position: sticky;
  top: -92px;
  height: 172px;
  z-index: 3;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: $border-radius_section;
  background-image: url('../../assets/images/header-inner/background-inner-wrapper@2x.webp');
  background-position: center 26%;
  background-repeat: no-repeat;
  background-size: 1920px;

  @media (min-width: 1920px) {
    background-size: 3840px;
  }

  @media (min-width: 3840px) {
    background-size: cover;
  }
}

.logo-wrapper {
  position: relative;
  display: block;
  width: 154px;
  height: auto;
  will-change: transform;

  & > * {
    width: 100%;
    height: auto;
  }
}
