@use '../../../variables.scss' as *;

.visually-hidden {
    @include visually-hidden;
}

.control-wrapper {
    position: absolute;
    top: 50%;
    left: 50px;
    display: none;
    width: fit-content;
    z-index: 3;
    transform: translateY(-50%);

    &--right {
        left: unset;
        right: 50px;
    }

    @include respond-to-desktop {
        display: flex;
    }
}

.control-button {
    display: none;
    align-items: center;
    justify-content: center;
    width: 48px;
    aspect-ratio: 1 / 1;
    border: none;
    border-radius: 50%;
    background-color: $background-color_modal-icon;
    transform: rotate(180deg);
    cursor: pointer;

    &--reversed {
        transform: rotate(0);
    }

    @include respond-to-desktop {
        display: flex;
    }
}
