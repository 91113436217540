@import '../../variables.scss';

.testimonial-card {
  width: 311px;
  min-height: 311px;
  padding: 24px;
  border-radius: 16px 16px 16px 0;
  background-color: $color_white;
}

.card-title {
  min-height: 48px;
  margin-bottom: 12px;
  text-align: left;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  text-align: left;
}

.review-text {
  min-height: 140px;
}

.review-info {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.review-stars {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  margin: 0;
}

.author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  line-height: 20px;

  @include respond-to-desktop {
    font-size: 20px;
  }
}

.location {
  display: flex;
  align-items: center;
  column-gap: 2px;
  font-size: 16px;
  line-height: 20px;
  color: $color_text_50_opacity;

  @include respond-to-desktop {
    font-size: 20px;
  }
}
