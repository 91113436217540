@import '../../variables.scss';

.page-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    position: relative;
    flex-grow: 1;
    background-color: $color_white;
    border-radius: $border-radius_section;
    z-index: 2;
}

.main-wrapper {
    max-width: $max-width_page;
    padding: 48px 16px;
    margin: 0 auto;

    @include respond-to-desktop {
        padding: 50px;
    }
}

.title {
    margin: 0;
    margin-bottom: -20px;
}

.paragraph {
    margin-block:  20px;
}

.line {
    margin: 0;
}

.link {
    text-decoration: none;
    color: $color_footer-link;
    word-break: break-word;
}
