@import '../../variables.scss';

.testimonials {
  position: relative;
  margin-top: -50px;
  padding-block: 98px 56px;
  border-radius: $border-radius_section;
  background-image: url('../../assets/images/patterns/entertainments-pattern.png');
  background-repeat: repeat;
  background-size: 2345px 408px;
  background-position: center;
  background-color: $color_background-pattern;
  z-index: 3;

  @include respond-to-desktop {
    margin-top: -100px;
    padding-block: 195px 80px;
  }
}

.slider-wrapper {
  max-width: $breakpoint_slider-testimonials;
  margin: 0 auto;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  position: relative;
  width: fit-content;
}

.title-decoration {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-70%) translateY(-30%);
  z-index: -1;
}
