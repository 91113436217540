@font-face {
    font-family: 'NATS';
    src: url('assets/fonts/NATS-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mouse Memoirs';
    src: url('assets/fonts/MouseMemoirs-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

:root {
    --fallback-fonts: -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html,
body {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    font-size: 20px;
    line-height: 1;
}

body {
    height: 100vh;
    font-family: 'NATS', var(--fallback-fonts);
    color: #373d4d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-open {
    overflow: hidden;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
