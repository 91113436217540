@import '../../variables.scss';

.title {
    margin: 0;
    font-family: 'Mouse Memoirs', var(--fallback-fonts);
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.15;
    color: $color_title;
}

.subtitle {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    color: $color_text;
}

.paragraph {
    margin: 0;
    color: $color_text;
}
