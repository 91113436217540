@use '../../../variables.scss' as *;

.slide {
  flex: 0 0 200px;

  &--card {
    flex: 0 0 311px;
  }
}

.slide-button {
  @include reset-button;

  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  &:disabled {
      cursor: not-allowed;
      pointer-events: none;
  }
}

.slide img {
  display: block;
  width: 100%;
  height: auto;
}
