@import '../../variables.scss';

.features {
  position: relative;
  margin-top: -50px;
  padding-block: 117px 56px;
  padding-inline: 24px;
  border-radius: $border-radius_section;
  background-image: url('../../assets/images/patterns/entertainments-pattern.png');
  background-repeat: repeat;
  background-size: 2345px 408px;
  background-position: center;
  background-color: $color_background-pattern;
  z-index: 4;

  @include respond-to-desktop {
    margin-top: -100px;
    padding-block: 195px 80px;
  }

  @media  (min-width: 1166px) {
    padding-top: 200px;
  }
}

.wrapper {
  max-width: 750px;
  margin: 0 auto;

  @include respond-to-desktop {
    max-width: 992px;
  }
}

.features-list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(2, minmax(min(159px, 100%), 1fr));
  justify-items: center;
  justify-content: center;
  column-gap: 8px;
  row-gap: 50px;
  max-width: 326px;
  margin: 0 auto;

  @include respond-to-desktop {
    grid-template-columns: repeat(4, minmax(min(159px, 100%), 1fr));
    column-gap: 8px;
    max-width: 660px;
  }

  @media  (min-width: 1166px) {
    grid-template-columns: repeat(4, minmax(min(159px, 100%), 1fr));
    column-gap: 24px;
    max-width: unset;
  }
}

.features-item {
  position: relative;
  width: 159px;
  padding: 24px 8px;
  padding-top: 118px;
  border-radius: $border-radius_features-card;
  background-color: $color_white;
  box-shadow: $box-shadow_feature-card;

  @media  (min-width: 1166px) {
    width: 230px;
    padding: 24px 15px;
    padding-top: 136px;
  }
}

.features-card-text {
  @media (min-width: 1166px) {
    font-size: 24px;
    line-height: 1;
  }
}

.image-wrapper {
  position: absolute;
  top: -35px;
  left: 50%;
  width: 144px;
  transform: translateX(-50%);

  @media (min-width: 1166px) {
    top: -60px;
    width: 190px;
  }
}

.image {
  width: 100%;
  height: auto;
}
