@import '../../variables.scss';

.header {
  position: relative;
  background-color: $color_background-pattern;
  z-index: 2;
}

.cover-wrapper {
  height: 458px;
  margin: 0 auto;
  border-radius: $border-radius_section;
  overflow: hidden;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-inline: auto;
}

.logo-wrapper {
  position: absolute;
  left: 50%;
  bottom: 3px;
  transform: translateX(-50%);

  & > * {
    width: 300px;
  }

  @include respond-to-desktop {
    bottom: 46px;

    & > * {
      width: 424px;
    }
  }
}
