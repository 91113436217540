@import '../../variables.scss';

.page-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    background-color: $color_white;
    border-radius: $border-radius_section;
}

.main-wrapper {
    padding: 77px 30px 65px 30px;
    margin: 0 auto;

    & > svg {
        width: 100%;
        margin-bottom: -30px;
    }

    @include respond-to-desktop {
        padding: 58px;

        & > svg {
            margin-bottom: 12px;
        }
    }
}

.text {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 24px;

    @include respond-to-desktop {
        margin-bottom: 8px;
        line-height: 32px;
    }
}

.link {
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-size: 24px;
    line-height: 24px;
    text-decoration: none;
    color: #7974e0;

    @include respond-to-desktop {
        line-height: 32px;
    }
}
