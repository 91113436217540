.page-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    position: relative;
    flex-grow: 1;
    z-index: 1;
}
